const greyWordsLvl1 = [
  "shortstack",
  "short stack",
  "anorexic",
  "crying"
];

const greyWordsLvl2 = [
  "ab13"
]

// banning fictional characters acted by real people in realistic styles
const styleSpecificBan = [
  "Houndoom",
  "wednesday addams",
  "dorothy gale",
  "hermione granger",
  "black widow",
  "black marvel widow",
  "scarlet marvel witch",
  "Natasha Romanoff",
  "scarlet witch",
  "rey skywalker",
  "rey from star wars",
  "Princess Leia",
  "wonder woman",
  "Wonder woma",
  "Jane foster",
  "khaleesi",
  "Daenerys Targaryen",
  "Daenerys",
  "Cersei Lannister",
  "Kirlia From Pokemon",
];

// banning fictional characters acted by real people in realistic styles
const styleSpecificBanStyles = [
  "studio",
  "realistic",
  "realisticphotography",
  "porno",
  "realisticasian",
  "kidol",
  "jav",
  "photo",
  "2.5d"
  // ... Add more names as needed
];

export function isShadowBanned(text: string, style: string) {
  // Normalize the input text: convert to lowercase, replace non-alphanumeric characters with space, trim, and collapse multiple spaces
  const cleanedText = text.toLowerCase().replace(/[^a-z0-9]/g, ' ').replace(/\s+/g, ' ').trim();

  // Check if any celebrity or grey word in the list matches the cleaned text
  const lists = [greyWordsLvl1, greyWordsLvl2];
  
  const isBannedTerm = lists.some((list) => 
    list.some((item) => {
      // Normalize each item in the same way as the input text
      const cleanedItem = item.toLowerCase().replace(/[^a-z0-9]/g, ' ').replace(/\s+/g, ' ').trim();
      return cleanedText.includes(cleanedItem);
    })
  );

  // Normalize style
  const cleanedStyle = style.toLowerCase().replace(/[^a-z0-9]/g, ' ').replace(/\s+/g, ' ').trim();

  // Check if the style matches any item in styleSpecificIds and if the text matches any term in styleSpecificBan
  const isBannedStyle = styleSpecificBanStyles.includes(cleanedStyle) && styleSpecificBan.some((item) => {
    const cleanedItem = item.toLowerCase().replace(/[^a-z0-9]/g, ' ').replace(/\s+/g, ' ').trim();
    return cleanedText.includes(cleanedItem);
  });

  // Return true if either condition is met
  return isBannedTerm || isBannedStyle;
}