export const furryBannedKeywords = [
  "human male",
  "human",
  "man",
  "man's",
  "little man",
  "muscular man",
  "guy",
  "1man",
  "male",
  "stuffed",
]

export const nonFurryBannedKeywords = [
  "fucked by dog",
  "fucked by a dog",
  "fucked by zebra",
  "fucked by a zebra",
  "dog cock",
  "lamb",
  "pig",
  "wolf",
  "wolves",
  "cow",
  "dog",
  "bear",
  "zebra",
  "insect",
  "bug",
  "bugs",
  "worm",
  "worms",
  "hornet",
  "hornets",
  "maggot",
  "maggots",
  "centipedes",
  "centipede",
  "cockroache",
  "cockroaches",
  "termites",
  "termite",
  "ant",
  "ants",
  "bull",
  "elephant",
  "mare",
  "equine",
  "stallion",
  "stalion",
  "golden retriever",
  "mastiff",
  "dane",
  "german shepherd",
  "german shepard",
  "dog cock",
  "Rottweiler",
  "doberman"
]