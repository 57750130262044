// Object containing the original terms and their replacements
export const replacementsKeywords: { [key: string]: string } = {
    "mom": "step mom",
    "mother": "step mother",
    "mothers": "step mother",
    "dad": "step dad",
    "sister": "step sister",
    "brother": "step brother",
    "femboy": "young man",
    "femboys": "young men",
    "girl": "young woman",
    "girs": "young women",
    "boy": "young man",
    "very": "",
    // Add more replacements as needed
  };