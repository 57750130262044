export const combinedCensorship = [
  ["mom", "mommy", "mother", "dad", "daddy", "father", "petite", "smalltits", "smallwaist", "smallhips", "smalldick", "small", "tits", "waist", "hips", "dick"],
  ["minotaur", "pony"],
  ["minotaur", "ponycock"],
  ["on all fours", "pony"],
  ["horse", "on all four"],
  ["horse", "on all fours"],
  ["equinehorse", "on all four"],
  ["equinehorse", "on all fours"],
  ["adorable smile", "Beautiful Eyes"],
  ["chocolate", "anal", "eruption"],
  ["smallbreasts", "smallnipples"],
  ["short ass", "short body", "short chest", "short breast"],
  ["skinny", "pigtails", "pigtail"],
  ["skinny", "very skinny", "thin"],
  ["smaller female", "shorter body", "short body", "skinny body"],
  ["tiny breasts", "tiny breast", "small ass"],
  ["small breasts", "tiny breast", "tiny breasts", "skinny", "small height", "small butt"],
  ["flat cheast", "male cheast"],
  ["skinny body", "slender body", "thin waist", "thin legs"],
  ["youthful", "miniature body", "tiny body", "skinny"],
  ["small breast", "small breasts", "petite", "petite pussy"],
  ["skinny body", "skinny legs"],
  ["skinny body", "thin legs"],
  ["skinny body", "thin waist"],
  ["short statue", "tiny body"],
  ["small", "troll"],
  ["little", "troll"],
  ["petite", "troll"],
  ["small", "fairy"],
  ["little", "fairy"],
  ["petite", "fairy"],
  ["small", "goblin"],
  ["little", "goblin"],
  ["petite", "goblin"],
  ["small", "dwarf"],
  ["little", "dwarf"],
  ["petite", "dwarf"],
  ["little", "girl"],
  ["petite", "girl"],
  ["small", "girl"],
  ["dwarf", "girl"],
  ["goblin", "girl"],
  ["little", "boy"],
  ["petite", "boy"],
  ["small", "boy"],
  ["dwarf", "boy"],
  ["goblin", "boy"],
  ["mini", "version"],
  ["deformed", "mutated", "disfigured"],
  ["short", "small", "body", "torso"],
];