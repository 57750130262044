const supportedStyles = [
  "photo",
  "2.5d",
  "sketch",
  "hentai",
  "painting",
  "lineart",
  "surreal",
  "porno",
  "dream",
  // "3dcartoon",
  "animation",
  "pastel",
  "webtoon",
  "artoon",
  "classic",
  "jav",
  // ... Add more names as needed
];

// Function to return the corresponding value of a key
export function isSDXL(style: string) {
  return supportedStyles.includes(style);
}
